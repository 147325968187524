import React from 'react';
import "./generalCarteavButton.css"; 

export const themeOptions = {
  BLACK: 'black',
  CARTEAV: 'carteav',
  GREY: 'grey',
  DANGER:'danger',
  WHITE: 'white',
  TEXT_CARTEAV: 'text-carteav',
  TEXT_BLACK: 'text-black',
  TEXT_GREY: 'text-grey',
};

function GeneralButton({ id = null, onClick, text, addedStyle = {}, icon = null, theme = themeOptions.TEXT_CARTEAV, isActive = false , small = false ,disableActiveAnimation = false}) {
  
  const themeClass = {
    [themeOptions.BLACK]: 'blackButton',
    [themeOptions.CARTEAV]: 'carteavButton',
    [themeOptions.GREY]: 'greyButton',
    [themeOptions.DANGER]: 'dangerButton',
    [themeOptions.WHITE]: 'whiteButton',
    [themeOptions.TEXT_CARTEAV]: 'textCarteavButton',
    [themeOptions.TEXT_BLACK]: 'textBlackButton',
    [themeOptions.TEXT_GREY]: 'textGreyButton',
  }[theme];

  return (
    <div
      id={id}
      className={`generalButton ${themeClass} ${isActive ? 'active' : ''} ${small ? 'smallButton' : ''} ${disableActiveAnimation ? 'disableTouchStyle' : ''}`}
      style={{ ...addedStyle }}
      onClick={onClick}
    >
      {icon && <div className="icon">{icon}</div>}
      <div className="text">{text}</div>
    </div>
  );
}

export default GeneralButton;
