import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import useSharedDate from "../../hooks/useContext";
import useCheckReservationContext from '../../hooks/useReservationContext';
import CloseIcon from '@mui/icons-material/Close';
import saveFeedbackRequest from '../../services/saveFeedback';
import { ReactComponent as Rate1 } from "../../assets/icons/ratesIcons/rateEmojie_1.svg";
import { ReactComponent as Rate2 } from "../../assets/icons/ratesIcons/rateEmojie_2.svg";
import { ReactComponent as Rate3 } from "../../assets/icons/ratesIcons/rateEmojie_3.svg";
import { ReactComponent as Rate4 } from "../../assets/icons/ratesIcons/rateEmojie_4.svg";
import { ReactComponent as Rate5 } from "../../assets/icons/ratesIcons/rateEmojie_5.svg";
import GeneralButton, { themeOptions } from '../Buttons/carteavButton/generalCarteavButton';
import renderCustomAlert from '../customAlert/customAlert';
import { AppText } from '../multiLanguage/multiLanguage';
import useLanguageAndServerData from '../../hooks/useLangugaeAndServerContext';

import './RateRide.css'




export default function RateRide({ user_id }) {
  const { language } = useLanguageAndServerData();

  const customIcons = {
    1: {
      icon: <Rate1 style={{ width: '36px', height: '36px' }} />, // Initially gray
      label: AppText.feedback.rate1.label[language],
      question: AppText.feedback.rate1.question[language],
    },
    2: {
      icon: <Rate2 className="rate-icon" style={{ width: '36px', height: '36px' }} />,
      label: AppText.feedback.rate2.label[language],
      question: AppText.feedback.rate2.question[language],
    },
    3: {
      icon: <Rate3 className="rate-icon" style={{ width: '36px', height: '36px' }} />,
      label: AppText.feedback.rate3.label[language],
      question: AppText.feedback.rate3.question[language],
    },
    4: {
      icon: <Rate4 className="rate-icon" style={{ width: '36px', height: '36px' }} />,
      label: AppText.feedback.rate4.label[language],
      question: AppText.feedback.rate4.question[language],
    },
    5: {
      icon: <Rate5 className="rate-icon" style={{ width: '36px', height: '36px' }} />,
      label: AppText.feedback.rate5.label[language],
      question: AppText.feedback.rate5.question[language],
    },
  };

  const successMessage = () => {
    renderCustomAlert({
      id: 'feedback',
      title: AppText.alert.success_feedback[language],
      bodyText: "",
      type: 'info',
      language: language,
      duration: 3000
    });
  }
  const noSuccessMessage = () => {
    renderCustomAlert({
      id: 'feedback',
      title: AppText.alert.no_success_feedback[language],
      bodyText: "",
      type: 'error',
      language: language,
      duration: 3000
    });
  }
  const {
    setShowRateDialog,
    setDontAskMeAgain
  } = useCheckReservationContext();

  const { appHeight } = useSharedDate();
  const containerHeight = appHeight - 77;

  const [ratingValue, setRatingValue] = useState(0);
  const [feedback, setFeedback] = useState('');
  const missionId = sessionStorage.getItem("lastClosedReservationId")


  const [activeIcon, setActiveIcon] = useState(null);

  const handleChange = (event, newValue) => {
    setRatingValue(newValue);
    setActiveIcon(newValue);
  };
  const handleSubmit = async () => {
    console.log(`Submitted rating: ${ratingValue}`);
    console.log(`Feedback: ${feedback}`);


    try {
      const serverResponse = await saveFeedbackRequest(user_id, missionId, ratingValue, feedback);

      // Handle server response as needed    
      setFeedback('');
      setShowRateDialog(false); // Close the dialog
      successMessage();
      console.log("Server response:", serverResponse);
    } catch (error) {
      // Handle error gracefully (display error message, etc.)
      noSuccessMessage();
      setShowRateDialog(false); // Close the dialog
      console.error("Error submitting feedback:", error);
    }

  };

  return (
    <div className="overlay" style={{ height: containerHeight }} > {/* Main container */}
      <CloseIcon
        style={{
          position: 'absolute',
          top: 10,
          [language === 'he' ? 'right' : 'left']: 10, // Conditional positioning
          cursor: 'pointer',
          fill: 'grey', // Adjust color as needed
        }}
        onClick={() => setShowRateDialog(false)}
      /> {/* Close Icon */}

      <div className="overlay-content" >
        <Typography variant="h5" gutterBottom className="title">
          {AppText.feedback.label[language]}
        </Typography>
        <div className="rating-icons-container"> {/* Container for icon row */}

          <div className="rating-icons">
            {[1, 2, 3, 4, 5].map((value) => (
              <div
                key={value}
                className={`rating-item ${activeIcon === value ? 'active' : ''}`}
                onClick={() => handleChange(null, value)}
                aria-label={customIcons[value].label}
              >
                <div className="rate-icon">
                  {customIcons[value].icon}
                </div>
                <Typography variant="body2" className="rating-label">
                  {customIcons[value].label}
                </Typography>
              </div>
            ))}
          </div>
        </div>
        {
          ratingValue === 0 && (
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              marginTop: '2rem',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <GeneralButton
                id={"submit-rate-btn"}
                onClick={() => { setDontAskMeAgain(true) }}
                text={AppText.feedback.buttons.dontAskAgain[language]}
                theme={themeOptions.GREY}
              />
              <GeneralButton
                id={"submit-rate-btn"}
                onClick={() => { setShowRateDialog(false) }}
                text={AppText.feedback.buttons.notNow[language]}
                theme={themeOptions.TEXT_CARTEAV}
              />
            </div>
          )
        }
        {ratingValue !== 0 && (
          <div className="question">
            <hr />
            <Typography variant="body1" align="center" marginTop={1} gutterBottom>
              {customIcons[ratingValue].question}
            </Typography>
            <TextField
              label={AppText.feedback.placeHolder[language]}
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              sx={{
                marginTop: '16px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'var(--carteav)',  // Use the variable 
                  },
                  '&:hover fieldset': {
                    borderColor: 'var(--carteav)', // Maintain on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'var(--carteav)',  // Maintain when focused
                  },
                },
              }}
            />

            {/* <FormControlLabel // Checkbox component
              control={<Checkbox checked={dontAskAgainLocal} onChange={(e) => setDontAskAgainLocal(e.target.checked)} />}
              label="Don't ask me again"
          />
           */}
            <GeneralButton
              id={"submit-rate-btn"}
              onClick={handleSubmit}
              text={AppText.feedback.buttons.submit[language]}
              theme={themeOptions.CARTEAV}
            />
          </div>
        )}
      </div>
    </div>
  );
}
