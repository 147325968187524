import { createContext, useEffect, useState } from "react";
import GetMapData from "../../services/mapData";
import { useHistory,useLocation } from "react-router-dom";
import React from 'react';

export const sharedDataContext = createContext({});

export const DataProvider = ({ children  }) => {

    const [mapData, setMapData] = useState(null);
    const history = useHistory();
    const location = useLocation();
    const [imageSrc,setImageSrc] = useState(null);
    const [appHeight, setAppHeight] = useState(window.innerHeight);


    useEffect(() => {
      const handleResize = () => {
        setAppHeight(window.innerHeight);
      };
      
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect( ()=>{

        const getMapFromServer = async ()=>{
            
            const mapResponse = await GetMapData();
            if(mapResponse !== 'err'){
                setMapData(mapResponse);
                if (mapResponse.hasOwnProperty('picture')) {
                    const map_image = mapResponse.picture;
                    try {
                        const { default: image } = await import(`../../assets${map_image}`);
                        setImageSrc(image);
                    }catch (error) {
                        console.error('Error loading image:', error);
                    }
                }
                
            }
            
        }
  
        // when refresh/get into to page where map component includes - must require map data 
        const currentPath = location.pathname;
        if(mapData ==null && (currentPath.includes('user') || currentPath.includes('mapping'))) { 
            getMapFromServer();
        }
    },[location])

        return (
            <sharedDataContext.Provider 
                value={{ 
                    mapData, 
                    history,
                    imageSrc,
                    appHeight,
                }}
            >
                {children}
            </sharedDataContext.Provider>
        )
}

export default sharedDataContext;
